import React from 'react';
import Link from 'next/link';
import Script from 'next/script';
import dayjs from 'dayjs';
import { siteTitle } from 'site.config.js';
import StickyAd from './footerStickyAd';

const { NEXT_PUBLIC_SITE_DOMAIN } = process.env;

const Footer = () => (
  <>
    <footer className="footer mt-auto py-4">
      <div className="container">
        <nav className="nav">
          <Link href="/terms/" rel="nofollow">
            <a className="nav-link text-muted pl-0">Terms</a>
          </Link>
          <Link href="/18-usc-2257/" rel="nofollow">
            <a className="nav-link text-muted pl-0">18 USC 2257</a>
          </Link>
          <a
            className="nav-link text-muted pl-0"
            href={`mailto:hi@${NEXT_PUBLIC_SITE_DOMAIN}`}
            rel="nofollow"
          >
            Contact Us
          </a>
          <a
            className="nav-link text-muted pl-0"
            href={`mailto:dmca@${NEXT_PUBLIC_SITE_DOMAIN}`}
            rel="nofollow"
          >
            DMCA
          </a>
        </nav>

        <div className="d-flex flex-wrap justify-content-between align-items-center text-center">
          <div className="text-muted my-2">
            LeakedNudeCelebs.com is the home of daily free leaked nudes from the hottest female
            Onlyfans, Instagram and Patreon models.
          </div>
          <span className="text-muted">
            Copyright &copy;&nbsp;
            {dayjs().format('YYYY')}
            &nbsp;
            {siteTitle}
          </span>
        </div>
      </div>
    </footer>
    <StickyAd />
    <Script
      strategy="lazyOnload"
      src="https://cdn.fluidplayer.com/v3/current/fluidplayer.min.js"
      onLoad={() => {
        document.querySelectorAll('video').forEach((el) => {
          window.fluidPlayer(el, {
            vastOptions: {
              adList: [
                {
                  roll: 'preRoll',
                  vastTag:
                    'https://www.videosprofitnetwork.com/watch.xml?key=59596a8522f8b79bf29395c04edc3f75',
                },
                {
                  roll: 'midRoll',
                  vastTag:
                    'https://www.videosprofitnetwork.com/watch.xml?key=59596a8522f8b79bf29395c04edc3f75',
                  timer: 8,
                },
                {
                  roll: 'midRoll',
                  vastTag:
                    'https://www.videosprofitnetwork.com/watch.xml?key=59596a8522f8b79bf29395c04edc3f75',
                  timer: 12,
                },
                {
                  roll: 'postRoll',
                  vastTag:
                    'https://www.videosprofitnetwork.com/watch.xml?key=59596a8522f8b79bf29395c04edc3f75',
                },
              ],
            },
          });
        });
      }}
    />
    {/* Social Bar */}
    <Script
      strategy="lazyOnload"
      src="//pl17872076.profitablegatetocontent.com/d2/a6/4e/d2a64eba2ad31cb70a95826399211494.js"
    />
    {/* Popunder */}
    <Script
      strategy="lazyOnload"
      src="//pl17872039.profitablegatetocontent.com/92/8c/40/928c40d8d149ac31a0bea1a69387e303.js"
    />
  </>
);

export default Footer;
