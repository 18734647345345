import React from 'react';
import Link from 'next/link';
import dayjs from 'dayjs';
import LazyImage from 'components/lazy-image';

const ListItem = ({ post, desc = true }) => (
  <div className="col mb-4" key={post.id}>
    <div key={post.id} className="card h-100">
      <Link href={`/posts/${post.id}/`}>
        <a title={post.title}>
          <LazyImage src={post.cover} alt={post.title} className="card-img-top" />
        </a>
      </Link>
      <div className="card-body p-3">
        <h3 className="card-title h6">
          <Link href={`/posts/${post.id}/`}>
            <a title={post.title}>{post.title}</a>
          </Link>
        </h3>
        {desc ? <p className="card-text mb-1">{`Watch ${post.title} online and free`}</p> : null}
        <p className="card-text">
          <small className="text-muted">{dayjs(post.created_at).format('YYYY-MM-DD')}</small>
        </p>
      </div>
    </div>
  </div>
);

export default ListItem;
