import React from 'react';
import ListItem from 'components/item';

const List = ({ data, desc = true, cols = 4 }) => {
  let rowColsClass = 'row row-cols-2 row-cols-md-3 row-cols-lg-4';
  if (cols === 3) {
    rowColsClass = 'row row-cols-2 row-cols-md-3';
  }
  if (cols === 2) {
    rowColsClass = 'row row-cols-2';
  }

  return (
    <div className={rowColsClass}>
      {data.map((post) => (
        <ListItem post={post} desc={desc} key={post.id} />
      ))}
    </div>
  );
};

export default List;
