import React from 'react';
import Link from 'next/link';

const categories = ['Latest'];

class Navbar extends React.Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      document.querySelector('.navbar-toggler').addEventListener('click', (e) => {
        e.preventDefault();
        document.querySelector('.navbar-toggler').classList.toggle('collapsed');
        document.querySelector('.navbar-collapse').classList.toggle('show');
      });
    }
  }

  render() {
    return (
      <nav className="navbar fixed-top navbar-light bg-white navbar-expand-lg border-bottom">
        <div className="container">
          <Link href="/">
            <a className="navbar-brand d-flex align-items-center">
              <span className="en">Leaked</span>
              <span className="en">Nude Celebs</span>
            </a>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              {categories.map((cate) => (
                <li key={cate} className="nav-item px-1">
                  <Link href="/">
                    <a className="nav-link" href="/">
                      {cate}
                    </a>
                  </Link>
                </li>
              ))}
              <li className="nav-item px-1">
                <a
                  className="nav-link font-weight-bold text-danger text-uppercase"
                  href="https://bit.ly/3rEj1dT"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  Sex Online
                </a>
              </li>
            </ul>
            <form className="ml-auto form-inline">
              <input
                className="form-control form-control-sm mr-sm-2 search-input"
                type="search"
                placeholder="Keyword"
              />
              <button className="btn btn-sm my-2 my-sm-0 search-btn" type="submit">
                Search
              </button>
            </form>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
