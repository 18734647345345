import React from 'react';
import LazyLoad from 'vanilla-lazyload';

if (typeof window !== 'undefined' && !document.lazyLoadInstance) {
  document.lazyLoadInstance = new LazyLoad();
}

class LazyImage extends React.Component {
  // Update lazyLoad after first rendering of every image
  componentDidMount() {
    if (typeof window !== 'undefined') {
      document.lazyLoadInstance.update();
    }
  }

  // Update lazyLoad after rerendering of every image
  componentDidUpdate() {
    if (typeof window !== 'undefined') {
      document.lazyLoadInstance.update();
    }
  }

  // Just render the image with data-src
  render() {
    const { alt, src } = this.props;
    const placeholder = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='500px' height='500px' viewBox='0 0 500 500' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Crect id='Rectangle' fill='%23EFEFEF' x='0' y='0' width='500' height='500' rx='8'%3E%3C/rect%3E%3Ctext id='leakedonlyfans' font-family='Helvetica Neue' font-size='20' font-weight='500' letter-spacing='0' fill='%23CCCCCC'%3E%3Ctspan x='156' y='252'%3ELEAKED NUDE CELEBS%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/svg%3E";
    return (
      <div className="img-wrap">
        <img
          referrer="no-referrer"
          alt={alt}
          className="lazy card-img-top rounded-0 img-element"
          data-src={src}
          src={placeholder}
          onError={(e) => {
            e.target.src = placeholder;
          }}
        />
      </div>
    );
  }
}

export default LazyImage;
