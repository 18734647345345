import React from 'react';
import Head from 'next/head';
import { siteTitle, siteDescription } from 'site.config';

import Navbar from './navbar';

const Header = ({ title, description, rel = 'index', category, children }) => {
  const t = title
    ? `${title} - ${siteTitle}`
    : `${siteTitle} - Best place for OnlyFans & more adult leaks.`;
  const d = description || siteDescription;

  return (
    <>
      <Head>
        <title>{t}</title>
        <meta name="robots" content={rel} />
        <meta name="description" content={d} />
        {children}
      </Head>
      <Navbar current={category} />
    </>
  );
};

export default Header;
