import React, { useState } from 'react';
import { Icon } from '@iconify/react';

const Ad = () => {
  const [visible, setVisible] = useState(true);

  if (visible) {
    return (
      <div className="p-2 fixed-bottom bg-dark">
        <a
          className="rounded bg-warning d-flex align-items-center justify-content-center"
          href="https://bit.ly/3llyMF5"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <div className="px-3">
            <Icon icon="fluent:cursor-click-24-filled" color="#000" height="36" width="36" />
          </div>
          <div className="d-flex flex-column align-items-center py-2">
            <div className="h4 font-weight-bold mb-0">CUM in 7 Seconds</div>
            <div className="h5 font-weight-normal mb-0">Can you last longer?</div>
          </div>
        </a>
        <button
          className="position-absolute text-white-50 btn btn-link"
          style={{ top: 0, right: 0, fontSize: '20px' }}
          onClick={() => setVisible(false)}
          type="button"
        >
          &times;
        </button>
      </div>
    );
  }

  return null;
};

export default Ad;
